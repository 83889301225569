import axios from 'axios';
const base_url = process.env.REACT_APP_DEVELOP_API;

const ordersService = {
  async getAllOrders(customerId: string, filters: { NextOrBefore: string; index: string }) {
    return axios.get(`${base_url}/orders/all/${customerId}`, { params: filters });
  },

  async returnOrderItemById(orderId: string, data: any) {
    return axios.post(`${base_url}/orders/${orderId}`, data);
  }
};
export default ordersService;
