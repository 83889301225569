import React, { useState } from 'react';
import { AddressIcon, CardIcon, CommandeIcon, InfoIcon, InvoiceIcon, LogoutIcon } from '../Icon';
import { Link } from 'react-router-dom';

interface SideBarProps {
  handleLogout: () => void;
}
const SideBar: React.FC<SideBarProps> = ({ handleLogout }) => {
  const [isHoveredCommand, setIsHoveredCommand] = useState(false);
  const [isHoveredAccount, setIsHoveredAccount] = useState(false);
  const [isHoveredAddress, setIsHoveredAddress] = useState(false);
  const [isHoveredMPayment, setIsHoveredMPayment] = useState(false);
  const [isHoveredPayment, setIsHoveredPayment] = useState(false);
  const [isHoveredLogOut, setIsHoveredLogOut] = useState(false);

  return (
    <div className="w-1/5 pt-[0.5px] flex flex-col h-full mobile:hidden justify-between pb-4">
      <div className="w-full gap-y-4 flex flex-col">
        <div className="flex items-center group">
          <CommandeIcon />
          <Link
            className="pl-4 font-lato text-sm text-gray-800 uppercase relative"
            to="/home/wardrobe"
            onMouseEnter={() => setIsHoveredCommand(true)}
            onMouseLeave={() => setIsHoveredCommand(false)}>
            <span className="relative">
              Mes commandes
              <span
                className={`absolute bottom-[-4px] w-0 h-[1px] bg-black transition-all duration-300 ${
                  isHoveredCommand ? 'group-hover:w-full left-0' : 'right-0'
                }`}></span>
            </span>
          </Link>
        </div>
        <div className="flex items-center group">
          <InfoIcon />
          <Link
            className="pl-4 font-lato text-sm text-gray-800 uppercase relative"
            to="/home/info"
            onMouseEnter={() => setIsHoveredAccount(true)}
            onMouseLeave={() => setIsHoveredAccount(false)}>
            <span className="relative">
              Mon compte
              <span
                className={`absolute bottom-[-4px] w-0 h-[1px] bg-black transition-all duration-300 ${
                  isHoveredAccount ? 'group-hover:w-full left-0' : 'right-0'
                }`}></span>
            </span>
          </Link>
        </div>
        <div className="flex items-center group">
          <AddressIcon />
          <Link
            className="pl-4 font-lato text-sm text-gray-800 uppercase relative"
            to="/home/address"
            onMouseEnter={() => setIsHoveredAddress(true)}
            onMouseLeave={() => setIsHoveredAddress(false)}>
            <span className="relative">
              Mes adresses
              <span
                className={`absolute bottom-[-4px] w-0 h-[1px] bg-black transition-all duration-300 ${
                  isHoveredAddress ? 'group-hover:w-full left-0' : 'right-0'
                }`}></span>
            </span>
          </Link>
        </div>
        <div className="flex items-center group">
          <CardIcon />
          <Link
            className="pl-4 font-lato text-sm text-gray-800 uppercase relative"
            to="/home/paymentMethode"
            onMouseEnter={() => setIsHoveredMPayment(true)}
            onMouseLeave={() => setIsHoveredMPayment(false)}>
            <span className="relative">
              Moyen de paiement
              <span
                className={`absolute bottom-[-4px] w-0 h-[1px] bg-black transition-all duration-300 ${
                  isHoveredMPayment ? 'group-hover:w-full left-0' : 'right-0'
                }`}></span>
            </span>
          </Link>
        </div>
        <div className="flex items-center group">
          <InvoiceIcon />
          <Link
            className="pl-4 font-lato text-sm text-gray-800 uppercase relative"
            to="/home/payment"
            onMouseEnter={() => setIsHoveredPayment(true)}
            onMouseLeave={() => setIsHoveredPayment(false)}>
            <span className="relative">
              Mes paiements
              <span
                className={`absolute bottom-[-4px] w-0 h-[1px] bg-black transition-all duration-300 ${
                  isHoveredPayment ? 'group-hover:w-full left-0' : 'right-0'
                }`}></span>
            </span>
          </Link>
        </div>
      </div>
      <div className="flex items-center group">
        <LogoutIcon />
        <button
          className="pl-4 font-lato text-sm text-gray-800 uppercase relative"
          onClick={handleLogout}
          onMouseEnter={() => setIsHoveredLogOut(true)}
          onMouseLeave={() => setIsHoveredLogOut(false)}>
          <span className="relative">
            SE DÉCONNECTER
            <span
              className={`absolute bottom-[-4px] w-0 h-[1px] bg-black transition-all duration-300 ${
                isHoveredLogOut ? 'group-hover:w-full left-0' : 'right-0'
              }`}></span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default SideBar;
