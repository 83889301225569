import { Address } from './components/Address';
import React from 'react';

export default function AddressPage() {
  return (
    <div id="address-page">
      <div className="container">
        <div className="pb-2 uppercase text-lg font-lato">Mes adresses</div>
        <Address />
      </div>
    </div>
  );
}
