import useAuth from '../../hooks/useAuth';
import { useMutation } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { accessTokenType } from '../types/accessToken.type';
import { CUSTOMER_ACCESS_TOKEN_CREATE } from '../../API/queries/Customer.query';
import { useRecoilValue } from 'recoil';
import { userStore } from '../../stores/UserStore.atom';
import { RegisterForm, ResetPasswordForm } from './components';
import { themeStore } from '../../stores/ThemeStore.atom';
import classNames from 'classnames';
import { ThemeType } from '../../@types/Theme.type';
import { User } from '../types/user.type';
import ThrowNotification from '../../components/ThrowNotification';
import { EyeIcon, EyeSlashIcon } from '../../Icon';

export default function LoginPage() {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [showRegisterForm, isShowRegisterForm] = useState<boolean>(false);
  const [showResetPasswordForm, isShowResetPasswordForm] = useState<boolean>(false);
  const [notification, setNotification] = useState({ type: '', message: '' });
  const [showPassword, setShowPassword] = useState(false);

  const user = useRecoilValue<User | undefined>(userStore);
  const theme = useRecoilValue<ThemeType | undefined>(themeStore);

  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const [customerAccessTokenCreate] = useMutation<accessTokenType>(CUSTOMER_ACCESS_TOKEN_CREATE, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    }
  });

  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, [user, theme]);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsError(false);
  };
  const handeNotification = (type: string, message: string) => {
    setNotification({ type, message });
    setTimeout(() => {
      setNotification({ type: '', message: '' });
    }, 3000);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setIsError(false);
  };
  const handleRegister = () => {
    isShowRegisterForm(false);
  };
  const handleReset = () => {
    isShowResetPasswordForm(false);
  };

  const handleLogin = async () => {
    try {
      const response = await customerAccessTokenCreate({
        variables: {
          email: email,
          password: password
        }
      });
      if (response?.data?.customerAccessTokenCreate.customerAccessToken) {
        const accessToken =
          response?.data?.customerAccessTokenCreate?.customerAccessToken?.accessToken;
        setAuth({ accessToken });
        navigate('/home');
      } else {
        const errorMessage = 'Unidentified customer';
        if (
          response?.data?.customerAccessTokenCreate?.customerUserErrors &&
          response?.data?.customerAccessTokenCreate?.customerUserErrors[0].message === errorMessage
        ) {
          setIsError(true);
          handeNotification('error', 'Email ou mot de passe invalide');
          console.log('Bad login');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="flex min-h-screen h-full" id="login-page">
      {notification.type && (
        <ThrowNotification message={notification.message} type={notification.type} />
      )}
      <div className="lg:w-1/2 lg:flex hidden" id="section-1-left">
        <img
          src="https://cdn.shopify.com/s/files/1/0668/7870/1809/files/Codress_Image_Page_Connexion1.jpg?v=1705593940"
          alt="left"
          className="object-cover h-full w-full "
        />
      </div>
      <div
        className={classNames(
          `lg:w-1/2 w-full flex justify-start items-center flex-col mlaptop:pl-20 mlaptop:pr-20 xl:pl-48 xl:pr-40 md:pb-3 md:px-20 mobile:px-3 mobile:pb-3`
        )}
        style={{ backgroundColor: `${theme?.brand.colors.primary[0].background}` }}
        id="section-1-right">
        <Link to="https://codress.com" className="my-20 w-1/2">
          <img src={theme?.brand?.logo?.image?.url} alt="logo.codress" className="w-full" />
        </Link>
        {showResetPasswordForm ? (
          <ResetPasswordForm remove={handleReset} />
        ) : (
          <>
            {showRegisterForm ? (
              <RegisterForm remove={handleRegister} />
            ) : (
              <>
                <div className="bg-white rounded flex flex-col py-[30px] px-[24px] justify-end items-start w-full border border-solid border-gray-100 shadow-non">
                  <div className="mb-5 uppercase font-lato">Nouvel utilisateur ?</div>
                  <button
                    className="bg-black flex justify-center items-center w-full py-[18px]"
                    onClick={() => isShowRegisterForm(true)}>
                    <span className="text-white text-[15px] font-normal leading-[22.5px] uppercase font-lato">
                      inscription
                    </span>
                  </button>
                </div>
                <div className="flex flex-col rounded mt-[15px] bg-white border border-solid border-gray-100 shadow-non w-full h-auto p-[25px]">
                  <span className="my-3 mb-[21px] font-lato uppercase">mon compte</span>
                  <div className="flex flex-col w-full">
                    <label
                      className={classNames(isError && 'text-red-500', 'mb-1 font-lato text-xs')}>
                      Email
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                      placeholder="Votre adresse email"
                      className={classNames(
                        isError && 'border-red-500',
                        'p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-non font-lato'
                      )}
                    />
                  </div>

                  <div className="flex flex-col w-full">
                    <div className="flex w-full justify-between">
                      <label
                        className={classNames(
                          isError && 'text-red-500',
                          'mb-1 mt-3 font-lato text-xs'
                        )}>
                        Mot de passe
                      </label>
                      <label
                        className="mb-1 mt-3 font-lato text-xs"
                        onClick={() => isShowResetPasswordForm(true)}>
                        Mot de passe oublié ?
                      </label>
                    </div>
                    <div className="flex w-full relative">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        required
                        placeholder="Votre mot de passe"
                        className={classNames(
                          isError && 'border-red-500',
                          'p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-non font-lato w-full'
                        )}
                      />
                      <div
                        className=" absolute top-[50%] transform -translate-y-1/2  right-2 text-gray-400 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                      </div>
                    </div>
                  </div>
                  <button
                    className="bg-black flex justify-center items-center w-full py-[18px] mb-6 mt-8"
                    onClick={handleLogin}>
                    <span className="text-white text-[15px] font-normal leading-[22.5px] uppercase font-lato">
                      connexion
                    </span>
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
