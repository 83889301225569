import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { User } from '../../types/user.type';
import { userStore } from '../../../stores/UserStore.atom';

import ThrowNotification from '../../../components/ThrowNotification';
import { Field, Form, Formik } from 'formik';
import classNames from 'classnames';
import { addressValidationSchema } from '../utils/AddressValidationSchema';
import { addressFormType } from '../types/AddressForm.type';
import { useMutation } from '@apollo/client';
import { CUSTOMER_ADDRESS_UPDATE } from '../../../API/queries/Customer.query';
import useAuth from '../../../hooks/useAuth';

export const Address: React.FC = () => {
  const user = useRecoilValue<User | undefined>(userStore);
  const [notification, setNotification] = useState<{ type: string; message: string }>({
    type: '',
    message: ''
  });
  const { auth } = useAuth();

  const [updateCustomerAddressQuery] = useMutation<any>(CUSTOMER_ADDRESS_UPDATE, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    }
  });

  const countries = [
    { code: 'FR', name: 'France' },
    { code: 'CA', name: 'Canada' },
    { code: 'GB', name: 'United Kingdom' }
  ];

  const showNotification = (type: string, message: string) => {
    setNotification({ type, message });
    setTimeout(() => {
      setNotification({ type: '', message: '' });
    }, 3000);
  };
  const defaultValue = useMemo(() => {
    return {
      phone: user?.defaultAddress?.phone ?? '+33',
      city: user?.defaultAddress?.city ?? '',
      country: user?.defaultAddress?.country ?? countries[0].name,
      postalCode: user?.defaultAddress?.zip ?? '',
      address: user?.defaultAddress?.address1 ?? ''
    };
  }, [user]);
  const updateCustomerAddress = async (values: addressFormType) => {
    if (user?.defaultAddress.id) {
      console.log(values, 'icicici');
      const response = await updateCustomerAddressQuery({
        variables: {
          customerAccessToken: auth.accessToken,
          id: user?.defaultAddress.id,
          address1: values.address,
          city: values.city,
          country: values.country,
          phone: values.phone,
          zip: values.postalCode
        }
      });
      if (response?.data?.customerAddressUpdate?.customerAddress) {
        showNotification('success', `Votre adresse a ete modifié avec succes`);
      } else {
        showNotification(
          'error',
          `Une erreur c'est produite au moment de la creation de votre compte`
        );
      }
    }
  };

  return (
    <div className="gap-y-2 flex flex-col font-lato">
      {notification.type && (
        <ThrowNotification message={notification.message} type={notification.type} />
      )}
      <Formik
        enableReinitialize={true}
        validationSchema={addressValidationSchema}
        initialValues={defaultValue}
        onSubmit={updateCustomerAddress}>
        {({ errors, touched, dirty }) => (
          <div className="flex flex-col w-2/3 verticalMobile:w-full mobile:w-full h-auto pt-2">
            <Form className="gap-y-2 flex flex-col">
              <div className="flex justify-between gap-x-2 verticalMobile:flex-col">
                <div className="flex flex-col w-[65%] mobile:w-full">
                  <Field
                    id="adresse"
                    name="address"
                    type="text"
                    placeholder="Votre adresse de livraison"
                    className={classNames(
                      errors.address && touched.address && 'border-red-500',
                      'p-[15px] flex h-[49px] border text-sm border-solid border-gray-300 shadow-non font-lato rounded'
                    )}
                  />
                </div>
                <div className="flex flex-col mobile:w-full w-[35%]">
                  <Field
                    id="postalCode"
                    name="postalCode"
                    type="text"
                    placeholder="Code postale"
                    className={classNames(
                      errors.postalCode && touched.postalCode && 'border-red-500',
                      'p-[15px] flex h-[49px] border text-sm border-solid border-gray-300 shadow-non font-lato rounded'
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-2 verticalMobile:flex-col">
                <div className="flex flex-col w-full">
                  <Field
                    id="city"
                    name="city"
                    placeholder="Ville"
                    type="text"
                    className={classNames(
                      errors.city && touched.city && 'border-red-500',
                      'p-[15px] flex h-[49px] border border-solid text-sm border-gray-300 shadow-non font-lato rounded'
                    )}
                  />
                </div>

                <div className="flex flex-col w-full">
                  <Field
                    id="country"
                    name="country"
                    as="select"
                    placeholder="Pays"
                    multiple={false}
                    className={classNames(
                      errors.country && touched.country && 'border-red-500',
                      'p-[15px] flex h-[49px] border text-sm border-solid border-gray-300 shadow-non font-inter rounded bg-white'
                    )}>
                    {countries.map((country) => (
                      <option key={country.code} value={country.name} className="bg-white">
                        {country.name}
                      </option>
                    ))}
                  </Field>
                </div>

                <div className="flex flex-col w-full">
                  <Field
                    id="phone"
                    name="phone"
                    type="+33"
                    placeholder="Tel mobile"
                    className={classNames(
                      errors.phone && touched.phone && 'border-red-500',
                      'p-[15px] flex h-[49px] border rounded border-solid text-sm border-gray-300 shadow-non font-inter'
                    )}
                  />
                </div>
              </div>

              <button
                type="submit"
                className={classNames(
                  !dirty && 'opacity-50 pointer-events-none',
                  'bg-black flex justify-center items-center p-2 w-full rounded text-white'
                )}>
                Enregistrer les modifications
              </button>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};
