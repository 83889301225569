import React from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { useRecoilValue } from 'recoil';
import { User } from '../../types/user.type';
import { userStore } from '../../../stores/UserStore.atom';
import { CloseIcon, LockIcon, QuestionIcon } from '../../../Icon';
import customerService from '../../../API/customer/Customer.service';

interface PaymentMethodModalProps {
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  showNotification: (type: string, message: string) => void;
}

const PaymentMethodModal: React.FC<PaymentMethodModalProps> = ({ onClose, showNotification }) => {
  const stripe = useStripe();
  const elements = useElements();
  const user = useRecoilValue<User | undefined>(userStore);

  const createPaymentMethod = async () => {
    try {
      const cardNumberElement = elements?.getElement(CardNumberElement);
      if (cardNumberElement && user) {
        const paymentMethod = await stripe?.createPaymentMethod({
          card: cardNumberElement,
          billing_details: {
            name: 'user.firstName',
            email: user?.email,
            address: {
              line1: user.defaultAddress.address1,
              city: user.defaultAddress.city,
              country: user.defaultAddress.countryCode
            }
          },
          type: 'card'
        });
        if (paymentMethod && paymentMethod.paymentMethod) {
          const id = user.id.split('/');
          await customerService.setPaymentMethodToCustomer(id[4], user.metafield.value, {
            paymentId: paymentMethod.paymentMethod.id
          });
          showNotification('success', `Methode de paiement ajouter `);
          onClose();
        }
      }
    } catch (e) {
      showNotification('error', `Une erreur s'est produite veuillez recommencer plus tard`);
      console.error('Error fetching data:', e);
    }
  };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-">
            <div className="flex w-full justify-between">
              <span>Moyen de paiement</span>
              <button
                onClick={onClose}
                type="button"
                className="hover:rotate-90 transform transition-transform duration-300 ease-in-out">
                <CloseIcon />
              </button>
            </div>
          </div>
          <div className="rounded-lg space-y-4 p-6">
            <div className="flex relative w-full">
              <CardNumberElement
                id="card-nr"
                className="text-sm text-gray-700 bg-white border rounded px-3 py-4 border-gray-300 h-[49px] w-full"
                options={{ showIcon: true }}
              />
              <div className=" absolute top-[50%] transform -translate-y-1/2  right-2 text-gray-400">
                <LockIcon />
              </div>
            </div>
            <div className="flex w-full gap-x-2">
              <div className="w-1/2">
                <CardExpiryElement
                  id="card-exp"
                  className="text-sm text-gray-700 bg-white border rounded px-3 py-4 border-gray-300 h-[49px]"
                />
              </div>
              <div className="w-1/2">
                <div className="flex relative w-full">
                  <CardCvcElement
                    id="card-cvc"
                    className="text-sm text-gray-700 bg-white border rounded px-3 py-4 border-gray-300 h-[49px] w-full"
                  />
                  <div className="absolute top-[50%] transform -translate-y-1/2  right-2 text-gray-400">
                    <QuestionIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 gap-y-4 sm:px-6 flex flex-col">
            <button
              onClick={createPaymentMethod}
              type="button"
              className="w-full justify-center bg-black hover:bg-white hover:text-black rounded border shadow-sm p-4 text-base font-medium text-white font-lato">
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentMethodModal;
