import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { useMutation } from '@apollo/client';
import { CUSTOMER_CREATE, CUSTOMER_ACCESS_TOKEN_CREATE } from '../../../API/queries/Customer.query';
import { customerCreationType } from '../@types/customerCreation.type';
import { accessTokenType } from '../../types/accessToken.type';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

interface RegisterFormProps {
  remove: () => void;
}
export const RegisterForm: React.FC<RegisterFormProps> = ({ remove }) => {
  const { setAuth } = useAuth();
  const [isConfirmedPassword, setIsConfirmedPassword] = useState<{
    isConfirm: boolean;
    message: string;
  }>({ isConfirm: true, message: '' });
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  const [createCustomerQuery] = useMutation<customerCreationType>(CUSTOMER_CREATE, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    }
  });

  const [customerAccessTokenCreate] = useMutation<accessTokenType>(CUSTOMER_ACCESS_TOKEN_CREATE, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    }
  });
  const createCustomer = async (values: any) => {
    if (values.password !== values.confirmPassword) {
      setIsConfirmedPassword({ isConfirm: false, message: 'Bad password' });
    } else if (values.password.length < 5) {
      setIsConfirmedPassword({ isConfirm: false, message: '5 caractères au minimum' });
    } else {
      const response = await createCustomerQuery({
        variables: {
          email: values.email,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          acceptsMarketing: values.marketing
        }
      });

      if (response.data?.customerCreate?.customer) {
        console.log(response.data);

        const res = await customerAccessTokenCreate({
          variables: {
            email: values.email,
            password: values.password
          }
        });
        if (res?.data?.customerAccessTokenCreate.customerAccessToken) {
          const accessToken =
            res?.data?.customerAccessTokenCreate?.customerAccessToken?.accessToken;
          setAuth({ accessToken });
          navigate('/home');
        }
      } else {
        if (response?.data?.customerCreate?.userErrors) {
          setIsError(true);
        }
      }
    }
  };
  return (
    <div className="bg-white flex flex-col py-[30px] px-[24px] justify-end items-start w-full border border-solid border-gray-100 shadow-non">
      <div className="flex justify-between w-full items-center">
        <div className="mb-5 uppercase font-lato">Nouvel utilisateur ?</div>
        <label
          className="font-lato text-xs hover:cursor-pointer opacity-50 hover:underline-offset-4 hover:underline"
          onClick={() => remove()}>
          Deja client ?
        </label>
      </div>
      <Formik
        enableReinitialize={true}
        onSubmit={createCustomer}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          marketing: true,
          password: '',
          confirmPassword: '',
          phone: '+33'
        }}>
        <Form className="h-full w-full flex-row">
          {isError && (
            <div className="flex justify-center font-lato text-sm text-red-500 mb-2 ">
              Une erreur est survenu veuillez re-essayer
            </div>
          )}
          <div className="flex gap-x-2 mobile:flex-col mobile:gap-y-2">
            <div className="flex flex-col w-full">
              <label htmlFor="firstName" className="mb-1 font-lato text-xs opacity-50">
                Prénom
              </label>
              <Field
                id="firstName"
                name="firstName"
                placeholder="Jane"
                type="text"
                className="p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-non font-lato"
              />
            </div>
            <div className="flex flex-col w-full">
              <label htmlFor="lastName" className="mb-1 font-lato text-xs opacity-50">
                Nom
              </label>
              <Field
                id="lastName"
                name="lastName"
                placeholder="Jane"
                type="text"
                className="p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-non font-lato"
              />
            </div>
          </div>
          <div className="mt-2 flex gap-x-2 mobile:flex-col mobile:gap-y-2">
            <div className="flex flex-col w-[65%] mobile:w-full">
              <label htmlFor="email" className="mb-1 font-lato text-xs opacity-50">
                Email
              </label>
              <Field
                id="email"
                name="email"
                placeholder="jane@exemple.com"
                type="email"
                className="p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-non font-lato"
              />
            </div>
            <div className="flex flex-col mobile:w-full w-[35%]">
              <label htmlFor="phone" className="mb-1 font-lato text-xs opacity-50">
                Tel mobile
              </label>
              <Field
                id="phone"
                name="phone"
                placeholder="+33"
                type="text"
                className="p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-non font-lato"
              />
            </div>
          </div>
          <div className="flex gap-x-2 mt-2 mobile:gap-y-2 mobile:flex-col">
            <div className="flex flex-col w-full">
              <label htmlFor="password" className="mb-1 font-lato text-xs opacity-50">
                Mot de passe
              </label>
              <Field
                id="password"
                name="password"
                placeholder="Mot de passe"
                type="password"
                className={classNames(
                  !isConfirmedPassword.isConfirm && 'border-red-500',
                  'p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-non font-lato'
                )}
              />
            </div>
            <div className="flex flex-col w-full">
              <label htmlFor="confirmPassword" className="mb-1 font-lato text-xs opacity-50">
                Mot de passe confirmation
              </label>
              <Field
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Mot de passe"
                type="password"
                className={classNames(
                  !isConfirmedPassword.isConfirm && 'border-red-500',
                  'p-[15px] flex h-[49px] border border-solid border-gray-300 shadow-non font-lato'
                )}></Field>
            </div>
          </div>
          {!isConfirmedPassword.isConfirm && (
            <div className="flex justify-center font-lato text-sm text-red-500 mt-1">
              {isConfirmedPassword.message}
            </div>
          )}
          <div className="mt-2">
            <div className="flex flex-row w-full items-center h-full py-1 gap-x-4">
              <Field
                id="marketing"
                name="marketing"
                placeholder="confirmPassword"
                type="checkbox"
                className=""
              />
              <label htmlFor="marketing" className=" font-lato text-xs opacity-50">
                Recevez nos invitations et soyez au courant de tout avant tout le monde.
              </label>
            </div>
          </div>
          <button
            className="bg-black flex justify-center items-center w-full py-[18px] mt-2"
            type="submit">
            <span className="text-white text-[15px] font-normal leading-[22.5px] uppercase font-lato">
              Créer votre compte
            </span>
          </button>
        </Form>
      </Formik>
    </div>
  );
};
