import axios from 'axios';
import { OrderInfo } from '../../@types/Product.type';

const base_url = process.env.REACT_APP_DEVELOP_API;

const checkoutService = {
  async buyProductOrder(customerId: string, data: { orderInfo: OrderInfo }) {
    return axios.post(`${base_url}/checkout/customer/${customerId}`, data);
  }
};
export default checkoutService;
