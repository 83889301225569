import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import ordersService from '../../API/orders/Orders.service';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { GET_CUSTOMER } from '../../API/queries/Customer.query';
import ProductModal from './components/ProductModal';
import ThrowNotification from '../../components/ThrowNotification';
import customerService from '../../API/customer/Customer.service';
import { LoadingIcon } from '../../Icon';
import { Order } from '../../API/orders/Order.entity';
import BuyingModal from './components/BuyingModal';
import { OrderInfo } from '../../@types/Product.type';

export default function WardrobePage() {
  const { auth } = useAuth();
  const [orders, setOrders] = useState<Order>();
  const [selectedRow, setSelectedRow] = useState<OrderInfo>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [buyingModalOpen, setBuyingModalOpen] = useState<boolean>(false);

  const [notification, setNotification] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data } = useQuery(GET_CUSTOMER, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    },
    variables: { customerAccessToken: auth.accessToken }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = data?.customer?.id.split('/');
        setIsLoading(true);
        const orders = await ordersService.getAllOrders(id[4], {
          NextOrBefore: 'next',
          index: 'null'
        });
        setOrders({ pageInfo: orders.data.pageInfo, products: orders.data.products });
        console.log(orders.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchCustomerShippingData = async (
      tmp: { timeUpdate: string; orderCount: number } | null
    ) => {
      try {
        const id = data?.customer?.id.split('/');
        await customerService.shippingCount(id[4], {
          timeUpdate: tmp?.timeUpdate ?? null,
          orderCount: tmp?.orderCount ?? null,
          id: id[4]
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (data?.customer) {
      if (
        data.customer &&
        data.customer.metafields[0]?.key === 'shippingCount' &&
        data.customer.metafields[0].value
      ) {
        const tmp = JSON.parse(data.customer.metafields[0].value);
        fetchCustomerShippingData(tmp);
      }
      fetchData();
    }
  }, [data, notification]);

  const handeNotification = (type: string, message: string) => {
    setNotification({ type, message });
    setTimeout(() => {
      setNotification({ type: '', message: '' });
    }, 3000);
  };
  const handlePageProduct = async (hasPage: string, index: string) => {
    try {
      const id = data?.customer?.id.split('/');
      const orders = await ordersService.getAllOrders(id[4], {
        NextOrBefore: hasPage,
        index
      });
      setOrders({ pageInfo: orders.data.pageInfo, products: orders.data.products });
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = (order: OrderInfo) => {
    setSelectedRow(order);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedRow(undefined);
    setModalOpen(false);
  };

  const buyingCloseModal = () => {
    setBuyingModalOpen(false);
  };

  return (
    <div id="wardrobe-page">
      {notification.type && (
        <ThrowNotification message={notification.message} type={notification.type} />
      )}
      <div className="flex justify-between w-full">
        <div className="uppercase text-lg	font-lato pb-4">Mes Commandes</div>
        <div className="flex gap-x-10 items-center">
          <div
            className={classNames(
              orders?.pageInfo.hasPreviousPage
                ? 'uppercase text-xs font-lato cursor-pointer'
                : 'uppercase text-xs font-lato opacity-50 pointer-events-none'
            )}
            onClick={() => orders && handlePageProduct('before', orders.pageInfo.startCursor)}>
            Précédent
          </div>
          <div
            className={classNames(
              orders?.pageInfo.hasNextPage
                ? 'uppercase text-xs font-lato cursor-pointer'
                : 'uppercase text-xs font-lato opacity-50 pointer-events-none'
            )}
            onClick={() => orders && handlePageProduct('next', orders.pageInfo.endCursor)}>
            Suivant
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="overflow-y-auto max-h-[400px] mobile:max-h-screen font-lato">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="sticky top-0 z-10 text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Image
                </th>
                <th scope="col" className="px-6 py-3">
                  Produit
                </th>
                <th scope="col" className="px-6 py-3">
                  Commande
                </th>
                <th scope="col" className="px-6 py-3">
                  Statut
                </th>
                <th scope="col" className="px-6 py-3"></th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {orders?.products &&
                orders?.products?.length > 0 &&
                orders?.products?.map((order: OrderInfo) => (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    key={order?.id}>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {order?.image && (
                        <img
                          src={order?.image.url}
                          alt="logo.codress"
                          className="object-fit w-10"
                        />
                      )}
                    </th>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      onClick={() => openModal(order)}>
                      {order?.name}
                    </th>
                    <td className="px-6 py-4"> {order?.orderName}</td>
                    <td className="px-6 py-4">{order.status}</td>
                    <td className="px-6 py-4 ">
                      <button
                        className="bg-black group hover:bg-white hover:outline outline-1 hover:outline-black flex justify-center items-center p-2 w-full"
                        onClick={() => {
                          setBuyingModalOpen(true);
                          setSelectedRow(order);
                        }}>
                        <span className="text-white group-hover:text-black text-[13px] font-normal leading-[22.5px] uppercase font-lato">
                          Acheter
                        </span>
                      </button>
                    </td>
                    <td className="px-6 py-4 ">
                      <button
                        className="hover:bg-black bg-white outline-1 outline outline-black group flex justify-center items-center p-2 w-full "
                        onClick={() => openModal(order)}>
                        <span className="group-hover:text-white text-black text-[13px] font-normal leading-[22.5px] uppercase font-lato">
                          Retourner
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {isLoading && (
          <div className="w-full h-full flex-col items-center z-50 flex justify-center py-32">
            <LoadingIcon />
            En cours de chargement
          </div>
        )}
        {orders?.products?.length === 0 && (
          <div className="w-full h-full flex-col items-center z-50 flex justify-center py-32">
            Pas de commande
          </div>
        )}
      </div>
      {modalOpen && selectedRow && (
        <ProductModal
          orderInfo={selectedRow}
          onClose={closeModal}
          showNotification={handeNotification}
        />
      )}
      {buyingModalOpen && selectedRow && (
        <BuyingModal
          orderInfo={selectedRow}
          onClose={buyingCloseModal}
          showNotification={handeNotification}
        />
      )}
    </div>
  );
}
