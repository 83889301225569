import React from 'react';
import {
  AddressIcon,
  CardIcon,
  CloseIcon,
  CommandeIcon,
  InfoIcon,
  InvoiceIcon,
  LogoutIcon,
  ProfileIcon
} from '../Icon';
import { Link } from 'react-router-dom';
import { User } from '../pages/types/user.type';

interface MobileSideBarProps {
  user: User;
  handleSidebar: () => void;
  handleLogout: () => void;
}
const MobileSideBar: React.FC<MobileSideBarProps> = ({ user, handleSidebar, handleLogout }) => {
  return (
    <div className="py-4">
      <div className="flex justify-between items-center mb-5 px-4">
        <img
          src="https://cdn.shopify.com/s/files/1/0668/7870/1809/files/Codress_Logo_Test.png?v=1705594725"
          alt="logo.codress"
          className="w-40"
        />
        <button
          onClick={handleSidebar}
          className="hover:rotate-90 transform transition-transform duration-300 ease-in-out">
          <CloseIcon />
        </button>
      </div>
      <ul className="gap-y-4 flex flex-col mt-9 px-4">
        <li>
          <div className="hover:border-l-2 flex items-center pl-2">
            <CommandeIcon />
            <Link
              className="pl-4 font-lato text-lg text-gray-800 uppercase"
              to="/home/wardrobe"
              onClick={handleSidebar}>
              Mes commandes
            </Link>
          </div>
        </li>
        <li>
          <div className="hover:border-l-2 flex items-center pl-2">
            <InfoIcon />
            <Link
              className="pl-4 font-lato text-lg text-gray-800 uppercase"
              to="/home/info"
              onClick={handleSidebar}>
              Mon compte
            </Link>
          </div>
        </li>
        <li>
          <div className="hover:border-l-2 flex items-center pl-2">
            <AddressIcon />
            <Link
              className="pl-4 font-lato text-lg text-gray-800 uppercase"
              to="/home/address"
              onClick={handleSidebar}>
              Mes adresses
            </Link>
          </div>
        </li>
        <li>
          <div className="hover:border-l-2 flex items-center pl-2">
            <CardIcon />
            <Link
              className="pl-4 font-lato text-lg text-gray-800 uppercase"
              to="/home/paymentMethode"
              onClick={handleSidebar}>
              Moyen de paiement
            </Link>
          </div>
        </li>
        <li>
          <div className="hover:border-l-2 flex items-center pl-2">
            <InvoiceIcon />
            <Link
              className="pl-4 font-lato text-lg text-gray-800 uppercase"
              to="/home/payment"
              onClick={handleSidebar}>
              Mes payments
            </Link>
          </div>
        </li>
      </ul>
      {user && (
        <div className="flex absolute bottom-3 flex-col w-full">
          <div className="flex items-center justify-center">
            <div className="w-52 rounded-lg border-2 bg-gray-500 bg-transparent p-4 text-center shadow-lg ">
              <div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-gray-500">
                <ProfileIcon />
              </div>
              <h2 className="mt-4 text-xl">
                <div>
                  {user.firstName} {user.lastName}
                </div>
              </h2>
              <p className="mb-4 text-gray-600 dark:text-gray-300">{user.email}</p>
              <div className="flex items-center hover:border-l-2">
                <LogoutIcon />
                <button
                  className="pl-4 font-lato text-sm text-gray-800 uppercase"
                  onClick={handleLogout}>
                  SE DÉCONNECTER
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileSideBar;
