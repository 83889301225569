import { PaymentMethod } from '../types/PaymentMethod.type';

export const InitialActiveStatusUtils = (
  reorderedPaymentMethods: PaymentMethod[],
  default_payment_method: string
) => {
  return reorderedPaymentMethods.reduce(
    (acc: { [key: string]: boolean }, paymentMethod, index: number) => {
      acc[index] = paymentMethod.id === default_payment_method;
      return acc;
    },
    {}
  );
};
