import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_CUSTOMER } from '../API/queries/Customer.query';
import { useSetRecoilState } from 'recoil';
import { userStore } from '../stores/UserStore.atom';
import { User } from '../pages/types/user.type';

export interface AuthContextProps {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<any>>;
}

const AuthContext = createContext({});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState(() => {
    const storedAuth = localStorage.getItem('auth');
    return storedAuth ? JSON.parse(storedAuth) : {};
  });
  const setUser = useSetRecoilState<User | undefined>(userStore);

  const [getUser] = useLazyQuery(GET_CUSTOMER, {
    context: {
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_API_TOKEN
      }
    }
  });

  useEffect(() => {
    const fetchData = async (token: string) => {
      try {
        const userData = await getUser({
          variables: { customerAccessToken: token }
        });
        setUser(userData?.data?.customer);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (auth) {
      fetchData(auth.accessToken);
    }
    localStorage.setItem('auth', JSON.stringify(auth));
  }, [auth]);

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
