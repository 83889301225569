import React from 'react';
import ordersService from '../../../API/orders/Orders.service';
import { useRecoilValue } from 'recoil';
import { userStore } from '../../../stores/UserStore.atom';
import classNames from 'classnames';
import { CloseIcon } from '../../../Icon';
import { User } from '../../types/user.type';
import { OrderInfo } from '../../../@types/Product.type';

interface ProductModalProps {
  orderInfo: OrderInfo;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  showNotification: (type: string, message: string) => void;
}

const ProductModal: React.FC<ProductModalProps> = ({ orderInfo, onClose, showNotification }) => {
  const user = useRecoilValue<User | undefined>(userStore);

  const returnProduct = async () => {
    const id = orderInfo?.orderId.split('/');
    const tmp = await ordersService.returnOrderItemById(id[4], { user, orderInfo });
    if (tmp && tmp.data?.returnRequest?.return?.status === 'REQUESTED') {
      showNotification(
        'success',
        "Votre demande de retour a été crée avec succès. Nous vous informerons dès qu'elle sera traitée."
      );
    } else {
      `Une erreur c'est produite veuillez recommencer plus tard`;
    }
    onClose();
  };

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex w-full justify-end">
              <button
                onClick={onClose}
                type="button"
                className="hover:rotate-90 transform transition-transform duration-300 ease-in-out">
                <CloseIcon />
              </button>
            </div>

            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900 font-lato"
                  id="modal-headline">
                  {orderInfo.name}
                </h3>
                <div className="mt-2 font-lato">
                  <p className="text-sm text-gray-500">{orderInfo.name}</p>
                  <p className="text-sm text-gray-500">{orderInfo.orderName}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 gap-y-4 sm:px-6 flex flex-col">
            <button
              className={classNames(
                orderInfo.fulfillmentStatus !== 'fulfilled' && 'pointer-events-none opacity-50',
                'bg-black flex justify-center items-center p-4 w-full rounded'
              )}
              onClick={returnProduct}>
              <span className="text-white text-[15px] font-normal leading-[22.5px] uppercase font-lato">
                {`Retourner l'article`}
              </span>
            </button>
            <button
              onClick={onClose}
              type="button"
              className="w-full justify-center rounded border border-black shadow-sm p-4 text-base font-medium text-black font-lato">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductModal;
