import React from 'react';
import classNames from 'classnames';

interface SwitchRadioProps {
  activeStatus: { [key: string]: boolean };
  index: number;
  paymentMethodId: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (index: number, paymentMethod: string) => void;
}
const SwitchRadio: React.FC<SwitchRadioProps> = ({
  activeStatus,
  paymentMethodId,
  onChange,
  index
}) => {
  const toggleSwitch = (index: number, paymentMethod: string) => {
    onChange(index, paymentMethod);
  };
  console.log(activeStatus[index]);

  return (
    <div className={classNames(activeStatus[index] && 'pointer-events-none', 'flex items-center')}>
      <input
        type="radio"
        className="hidden"
        id={`switch-checkbox-${index}`}
        checked={activeStatus[index] ?? false}
        onChange={() => toggleSwitch(index, paymentMethodId)}
      />
      <label
        htmlFor={`switch-checkbox-${index}`}
        className={`cursor-pointer w-10 rounded-full p-1 duration-300 ${
          activeStatus[index] ? 'bg-green-400' : 'bg-gray-400'
        }`}>
        <div
          className={`h-4 w-4 bg-white rounded-full shadow-md transform duration-300 ${
            activeStatus[index] ? 'translate-x-4' : 'translate-x-0'
          }`}
        />
      </label>
    </div>
  );
};

export default SwitchRadio;
