import React from 'react';

interface NotificationProps {
  message: string;
  type: string;
}

const ThrowNotification: React.FC<NotificationProps> = ({ message, type }) => {
  const getAlertClasses = () => {
    switch (type) {
      case 'success':
        return 'bg-green-50 text-green-800';
      case 'error':
        return 'bg-red-50 text-red-800';
      case 'info':
        return 'bg-blue-50 text-blue-800';
      default:
        return '';
    }
  };

  return (
    <div
      className={`p-4 mb-4 text-sm rounded-lg ${getAlertClasses()} top-2 right-2 fixed z-50`}
      role="alert">
      <span className="font-medium font-lato">
        {type.charAt(0).toUpperCase() + type.slice(1)} alert!{' '}
      </span>
      {message}
    </div>
  );
};

export default ThrowNotification;
