import React from 'react';

export const VisaIcon: () => React.JSX.Element = () => {
  return (
    <svg width="38" height="25" viewBox="0 0 38 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_72_3113)">
        <path
          d="M35 0.690002H3C1.3 0.690002 0 1.99 0 3.69V21.69C0 23.39 1.4 24.69 3 24.69H35C36.7 24.69 38 23.39 38 21.69V3.69C38 1.99 36.6 0.690002 35 0.690002Z"
          fill="#DEDEDE"
        />
        <path
          d="M35 1.69C36.1 1.69 37 2.59 37 3.69V21.69C37 22.79 36.1 23.69 35 23.69H3C1.9 23.69 1 22.79 1 21.69V3.69C1 2.59 1.9 1.69 3 1.69H35Z"
          fill="white"
        />
        <path
          d="M28.3 10.79H28C27.6 11.79 27.3 12.29 27 13.79H28.9C28.6 12.29 28.6 11.59 28.3 10.79ZM31.2 16.69H29.5C29.4 16.69 29.4 16.69 29.3 16.59L29.1 15.69L29 15.49H26.6C26.5 15.49 26.4 15.49 26.4 15.69L26.1 16.59C26.1 16.69 26 16.69 26 16.69H23.9L24.1 16.19L27 9.39C27 8.89 27.3 8.69 27.8 8.69H29.3C29.4 8.69 29.5 8.69 29.5 8.89L30.9 15.39C31 15.79 31.1 16.09 31.1 16.49C31.2 16.59 31.2 16.59 31.2 16.69ZM17.8 16.39L18.2 14.59C18.3 14.59 18.4 14.69 18.4 14.69C19.1 14.99 19.8 15.19 20.5 15.09C20.7 15.09 21 14.99 21.2 14.89C21.7 14.69 21.7 14.19 21.3 13.79C21.1 13.59 20.8 13.49 20.5 13.29C20.1 13.09 19.7 12.89 19.4 12.59C18.2 11.59 18.6 10.19 19.3 9.49C19.9 9.09 20.2 8.69 21 8.69C22.2 8.69 23.5 8.69 24.1 8.89H24.2C24.1 9.49 24 9.99 23.8 10.59C23.3 10.39 22.8 10.19 22.3 10.19C22 10.19 21.7 10.19 21.4 10.29C21.2 10.29 21.1 10.39 21 10.49C20.8 10.69 20.8 10.99 21 11.19L21.5 11.59C21.9 11.79 22.3 11.99 22.6 12.19C23.1 12.49 23.6 12.99 23.7 13.59C23.9 14.49 23.6 15.29 22.8 15.89C22.3 16.29 22.1 16.49 21.4 16.49C20 16.49 18.9 16.59 18 16.29C17.9 16.49 17.9 16.49 17.8 16.39ZM14.3 16.69C14.4 15.99 14.4 15.99 14.5 15.69C15 13.49 15.5 11.19 15.9 8.99C16 8.79 16 8.69 16.2 8.69H18C17.8 9.89 17.6 10.79 17.3 11.89C17 13.39 16.7 14.89 16.3 16.39C16.3 16.59 16.2 16.59 16 16.59L14.3 16.69ZM5 8.89C5 8.79 5.2 8.69 5.3 8.69H8.7C9.2 8.69 9.6 8.99 9.7 9.49L10.6 13.89C10.6 13.99 10.6 13.99 10.7 14.09C10.7 13.99 10.8 13.99 10.8 13.99L12.9 8.89C12.8 8.79 12.9 8.69 13 8.69H15.1C15.1 8.79 15.1 8.79 15 8.89L11.9 16.19C11.8 16.39 11.8 16.49 11.7 16.59C11.6 16.69 11.4 16.59 11.2 16.59H9.7C9.6 16.59 9.5 16.59 9.5 16.39L7.9 10.19C7.7 9.99 7.4 9.69 7 9.59C6.4 9.29 5.3 9.09 5.1 9.09L5 8.89Z"
          fill="#142688"
        />
      </g>
      <defs>
        <clipPath id="clip0_72_3113">
          <rect width="38" height="24" fill="white" transform="translate(0 0.690002)" />
        </clipPath>
      </defs>
    </svg>
  );
};
