import React, { JSX, useState } from 'react';

interface Tab {
  title: string;
  content: JSX.Element;
}

export const Tabs: React.FC<{ tabs: Tab[] }> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="">
      <div className="flex">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-4 pb-2 uppercase text-lg font-lato ${index === activeTab ? 'border-b-2 border-gray-200' : ''}`}
            onClick={() => setActiveTab(index)}>
            {tab.title}
          </button>
        ))}
      </div>
      <div className="p-4">{tabs[activeTab].content}</div>
    </div>
  );
};
