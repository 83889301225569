import { Tabs } from '../../components/Tabs';
import { Invoices } from './components/Invoices';
import { UncomingInvoice } from './components/UncomingInvoice';

export default function PaymentPage() {
  const tabs = [
    {
      title: 'Mes factures',
      content: <Invoices />
    },
    {
      title: 'Prochaine facture',
      content: <UncomingInvoice />
    }
  ];

  return (
    <div id="payment-page">
      <div className="container">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
}
