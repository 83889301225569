import React, { useState } from 'react';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userStore } from '../stores/UserStore.atom';
import { SideBarIcon } from '../Icon';
import MobileSideBar from './MobileSidebar';
import SideBar from './Sidebar';
import { User } from '../pages/types/user.type';
import { ThemeType } from '../@types/Theme.type';
import { themeStore } from '../stores/ThemeStore.atom';
import classNames from 'classnames';

type LayoutProps = {};

const Layout: React.FC<LayoutProps> = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState<User | undefined>(userStore);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const theme = useRecoilValue<ThemeType | undefined>(themeStore);

  const handleLogout = async () => {
    try {
      setAuth({});
      localStorage.removeItem('auth');
      setUser(undefined);
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };
  const handleSidebar = async () => {
    setIsSidebarOpen(false);
  };

  return (
    <React.Fragment>
      <div className="h-screen flex flex-col">
        <div className="relative w-full h-1/3 verticalMobile:h-24">
          <Link to="https://codress.com">
            <img
              src={theme?.brand?.logo?.image?.url}
              alt="logo.codress"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-52 verticalMobile:w-40"
            />
          </Link>
          <button
            className="absolute top-0 left-0 mt-2 ml-2 w-10 h-10 mobile:flex hidden justify-center items-center"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <SideBarIcon />
          </button>
          <img
            src="https://cdn.shopify.com/s/files/1/0668/7870/1809/files/Codress_Image_Page_Connexion2.jpg?v=1705593944"
            alt="left"
            className="object-cover w-full h-full"
          />
        </div>
        <div
          className={classNames(
            `flex h-full px-20 pt-12 verticalMobile:pt-12 mobile:px-3 flex-row md:px-10`
          )}
          style={{ backgroundColor: `${theme?.brand.colors.primary[0].background}` }}>
          <SideBar handleLogout={handleLogout} />
          <div className="w-full relative">
            <Outlet />
          </div>
          <div
            className={`${
              isSidebarOpen ? 'block' : 'hidden'
            } fixed inset-0 z-10 bg-gray-900 bg-opacity-50 w-full h-full`}
            onClick={() => setIsSidebarOpen(false)}></div>
          <div
            className={`${
              isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            } fixed top-0 left-0 z-50 bg-white h-full w-2/3 max-w-xs overflow-y-auto transform transition-transform duration-300 ease-in-out rounded-r-xl`}>
            {user && (
              <MobileSideBar
                user={user}
                handleSidebar={handleSidebar}
                handleLogout={handleLogout}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
