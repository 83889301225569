import { Account } from './components/Account';
import React from 'react';

export default function CustomerPage() {
  return (
    <div id="customer-page">
      <div className="container">
        <div className="pb-2 uppercase text-lg font-lato">Mon compte</div>
        <Account />
      </div>
    </div>
  );
}
