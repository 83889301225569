import React from 'react';

export const MasterCardIcon: React.FC = () => {
  return (
    <svg width="38" height="25" viewBox="0 0 38 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_72_3117)">
        <path
          d="M35 0.690002H3C1.3 0.690002 0 1.99 0 3.69V21.69C0 23.39 1.4 24.69 3 24.69H35C36.7 24.69 38 23.39 38 21.69V3.69C38 1.99 36.6 0.690002 35 0.690002Z"
          fill="#DEDEDE"
        />
        <path
          d="M35 1.69C36.1 1.69 37 2.59 37 3.69V21.69C37 22.79 36.1 23.69 35 23.69H3C1.9 23.69 1 22.79 1 21.69V3.69C1 2.59 1.9 1.69 3 1.69H35Z"
          fill="white"
        />
        <path
          d="M15 19.69C18.866 19.69 22 16.556 22 12.69C22 8.82401 18.866 5.69 15 5.69C11.134 5.69 8 8.82401 8 12.69C8 16.556 11.134 19.69 15 19.69Z"
          fill="#EB001B"
        />
        <path
          d="M23 19.69C26.866 19.69 30 16.556 30 12.69C30 8.82401 26.866 5.69 23 5.69C19.134 5.69 16 8.82401 16 12.69C16 16.556 19.134 19.69 23 19.69Z"
          fill="#F79E1B"
        />
        <path
          d="M22 12.69C22 10.29 20.8 8.18999 19 6.98999C17.2 8.28999 16 10.39 16 12.69C16 14.99 17.2 17.19 19 18.39C20.8 17.19 22 15.09 22 12.69Z"
          fill="#FF5F00"
        />
      </g>
      <defs>
        <clipPath id="clip0_72_3117">
          <rect width="38" height="24" fill="white" transform="translate(0 0.690002)" />
        </clipPath>
      </defs>
    </svg>
  );
};
